/*--------------------------------------------------
サイトのテーマカラーに関する設定
--------------------------------------------------*/
// サイトのテーマカラー定義
$primary: #232d68;
$secondary: #9f8a6b;
$accent: #e15f35;
$gray: #d9d9d9;
$black: #000000;
$white: #ffffff;

// 明るい色と暗い色を`lighten()`関数と`darken()`関数で指定する。
$palettes: (
  primary: (
    base: $primary,
    light: lighten($primary, 10%),
    lighter: lighten($primary, 30%),
    lightest: lighten($primary, 40%),
    dark: darken($primary, 10%),
  ),
  secondary: (
    base: $secondary,
    dark: darken($secondary, 10%),
    lighter: lighten($secondary, 20%),
    lightest: lighten($secondary, 38%),
  ),
  accent: (
    base: $accent,
    light: lighten($accent, 10%),
    lighter: lighten($accent, 20%),
    lightest: lighten($accent, 30%),
    dark: darken($accent, 10%),
  ),
  gray: (
    base: $gray,
    light: lighten($gray, 5%),
    lighter: lighten($gray, 10%),
    dark: darken($gray, 10%),
    darken: darken($gray, 25%),
  ),
  black: (
    base: $black,
    light: lighten($black, 10%),
    lighter: lighten($black, 25%),
    lightest: lighten($black, 39%),
    dark: darken($black, 10%),
  ),
);

// `map-get()`関数をネストして値を取得する。
// ネストしたvalueのデフォルトはbaseとする。
@function palettes($key, $tone: base) {
  @return map-get(map-get($palettes, $key), $tone);
}

/*--------------------------------------------------
フォントに関する定義
--------------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Qwitcher+Grypen:wght@400;700&family=Shippori+Mincho:wght@400;500;700&display=swap');
// フォントファミリー定義
$family-primary: 'Shippori Mincho', serif;
$family-secondary: 'Qwitcher Grypen', cursive;

// フォントサイズ定義
$size-l: 3vh !default;
$size-1: 2.5vh !default;
$size-2: 2vh !default;
$size-3: 1.5vh !default;
$size-4: 1.3vh !default;
$size-5: 1.2vh !default;
$size-6: 1vh !default;
$size-7: 0.8vh !default;

/*--------------------------------------------------
スペーサーに関する定義
--------------------------------------------------*/
// インナーのX方向のパッディング定数
$px-inner-s: 3vw !default;
$px-inner-m: 4vw !default;
$px-inner-l: 6vw !default;

// 要素間の間を指定するためのスペーサー
$space-1: 50px !default;
$space-2: 40px !default;
$space-3: 30px !default;
$space-4: 25px !default;
$space-5: 15px !default;
$space-6: 8px !default;
$space-7: 5px !default;

$space-l: 70px !default;
$space-xl: 100px !default;

/*--------------------------------------------------
コンテンツ幅に関する定義
--------------------------------------------------*/
$width-s: 750px !default;
$width-m: 1000px !default;
$width-l: 1500px !default;
