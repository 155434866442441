@import './_variables';
@import './_mixin';
@import './_reset';
@import './_base';

/*--------------------------------------------------
サイト全体のスタイル
--------------------------------------------------*/
.lp-wrapper {
  position: relative;
  overflow: hidden;
}

.lp-body {
  position: relative;
  max-height: 100vh;
  display: flex;
  flex-wrap: nowrap;
  will-change: auto;
}

.section {
  position: relative;
  min-height: 100vh;
  background: rgba(255, 253, 251, 0.85);
}

.lp-bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: -1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }
}

/*--------------------------------------------------
テキストスタイル
--------------------------------------------------*/
.text-secondary {
  font-family: $family-secondary;
}

.text-vertical {
  line-height: 2;
  letter-spacing: 0.1em;
  writing-mode: vertical-rl;
}

/*--------------------------------------------------
ボタン
--------------------------------------------------*/
.c-btn-header {
  display: inline-block;
  padding: $space-7 $space-4;
  font-size: $size-2;
  letter-spacing: 0.1em;
  word-break: keep-all;
  color: $white;
  background-color: #48594a;
  border: 1px solid #48594a;
  transition: all 0.3s;

  &:hover {
    color: #48594a;
    background-color: $white;
  }
}

.c-btn {
  position: relative;
  display: flex;
  justify-content: space-between;
  min-width: 210px;
  width: fit-content;

  &::before {
    content: '';
    position: absolute;
    display: inline-block;
    top: calc(50% - 6px);
    right: 50px;
    width: 20px;
    height: 7px;
    border-bottom: 1px solid $white;
    border-right: 1px solid $white;
    transform: skew(30deg);
    opacity: 0;
    transition: all 0.5s;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    display: inline-block;
    top: calc(50% - 6px);
    right: 27px;
    width: 20px;
    height: 7px;
    border-bottom: 1px solid #48594a;
    border-right: 1px solid #48594a;
    transform: skew(30deg);
    transition: all 0.5s;
    z-index: 1;
  }

  &:hover {
    transition: all 0.5s;

    &::before {
      right: 27px;
      opacity: 1;
    }

    &::after {
      right: 4px;
      opacity: 0;
    }

    .c-btn_text {
      color: #48594a;
    }

    .c-btn_hexagon_bg {
      opacity: 1;
    }
  }

  &_text {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 2rem;
    color: $black;
    transition: all 0.5s;
  }

  &_hexagon {
    position: relative;
    width: 69.2px;
    height: 60px;

    &_border {
      width: 34.6px;
      height: 60px;
      position: absolute;
      border-top: 1px solid #48594a;
      border-bottom: 1px solid #48594a;
      transform-origin: center;
      box-sizing: border-box;
      z-index: 0;

      &:nth-of-type(1) {
        transform: rotate(30deg);
      }

      &:nth-of-type(2) {
        transform: rotate(90deg);
      }

      &:nth-of-type(3) {
        transform: rotate(150deg);
      }
    }

    &_bg {
      opacity: 0;
      position: absolute;
      top: -3.5px;
      right: 5.5px;
      width: 58.3px;
      height: 67px;
      clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
      background: #a2ada4;
      z-index: -1;
      transition: all 0.5s;
    }
  }
}

/*--------------------------------------------------
タイトル
--------------------------------------------------*/
.c-title-section {
  position: relative;
  mix-blend-mode: multiply;
  opacity: 0.5;
  word-break: keep-all;
  white-space: nowrap;

  .en {
    display: inline-block;
    font-size: 13rem;
    transform: rotate(-15deg);
    background: linear-gradient(180deg, #232d68 0%, #644921 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

    @include mq-up(lg) {
      font-size: 20rem;
    }
  }

  .ja {
    display: block;
    position: absolute;
    bottom: 40px;
    left: 180px;
    font-size: $size-2;
    font-weight: 700;
    letter-spacing: 0.1em;
    background: linear-gradient(180deg, #232d68 0%, #644921 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  &.white {
    mix-blend-mode: inherit;
    opacity: inherit;
    color: $white;

    .en {
      -webkit-text-fill-color: inherit;
    }

    .ja {
      -webkit-text-fill-color: inherit;
    }
  }
}

.c-title-label {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  margin-left: $space-4;

  span {
    display: inline-block;
    padding: 12px 5px;
    font-size: $size-1;
    letter-spacing: 0.3em;
    background-color: $white;
    writing-mode: vertical-rl;

    &:nth-of-type(2) {
      margin-right: $space-5;
      margin-bottom: -30px;
    }
  }
}

/*--------------------------------------------------
ヘッダー
--------------------------------------------------*/
.header {
  z-index: 1100;
  position: fixed;
  padding: 5vh;
  width: 100vw;
  transition: opacity 0.8s ease;
  color: $white;

  &_inner {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &_logo {
    mix-blend-mode: difference;
    width: 25vw;

    @include mq-up(lg) {
      width: inherit;
    }
  }

  &-menu-pc {
    position: relative;
    //display: none;

    /*
    @include mq-up(lg) {
      display: block;
    }*/

    &_nav {
      display: flex;
      padding-right: 150px;

      &_list {
        padding: 0 $space-5;
        font-size: $size-3;
        line-height: 30px;
        letter-spacing: 0.1em;

        @include mq-up(lg) {
          font-size: $size-3;
        }
      }

      &_item {
        position: relative;
        padding-left: 20px;

        &.is-active {
          &::before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            width: 6px;
            height: 6px;
            background-color: $black;
            border-radius: 50%;
          }
        }
      }
    }

    &_btn {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
/*--------------------------------------------------
メインビジュアル
--------------------------------------------------*/
.lp-kv {
  position: relative;
  min-width: 100vw;
  z-index: 0;
  background: none;

  &_inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &_video {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-size: cover;

    video {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 0;
      min-width: 100%;
      min-height: 100%;
      transform: translate3d(-50%, -50%, 0);
      transition: all 0.8s;
    }
  }

  &_catch {
    position: absolute;
    left: 8vh;
    bottom: 25vh;
    letter-spacing: 0.1em;

    @include mq-up(lg) {
      bottom: 15vh;
    }

    .ja {
      display: block;
      margin-bottom: $space-5;
      font-size: $size-l;
      color: $white;
    }

    .en {
      font-size: $size-5;
      color: $gray;
    }
  }

  &_scrollDown {
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    bottom: 10vh;
    color: $white;
    text-decoration: none;
    z-index: 2;

    @include mq-up(lg) {
      bottom: 12vh;
    }

    &_text {
      display: inline-block;
      margin-right: $space-5;
      font-size: 1.4rem;

      @include mq-up(lg) {
        font-size: 1.6rem;
      }
    }

    &_base {
      content: '';
      display: inline-block;
      width: 80px;
      height: 3px;
      background: $gray;
      opacity: 0.3;

      @include mq-up(md) {
        width: 120px;
      }

      @include mq-up(xl) {
        width: 120px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      width: 80px;
      height: 3px;
      background: $white;
      animation: sdl 2s cubic-bezier(1, 0, 0, 1) infinite;

      @include mq-up(md) {
        width: 120px;
      }

      @include mq-up(xl) {
        width: 120px;
      }
    }
  }
}

@keyframes sdl {
  0% {
    transform: scale(0, 1);
    transform-origin: 0 0;
  }
  50% {
    transform: scale(1, 1);
    transform-origin: 0 0;
  }
  50.1% {
    transform: scale(1, 1);
    transform-origin: 100% 0;
  }
  100% {
    transform: scale(0, 1);
    transform-origin: 100% 0;
  }
}

/*--------------------------------------------------
About
--------------------------------------------------*/
.lp-about {
  position: relative;
  display: flex;
  min-width: 2150px;
  width: 200vh;
  height: 100%;

  @include mq-up(md) {
    width: 190vh;
  }

  @include mq-up(lg) {
    width: 200vh;
  }

  &_title {
    position: relative;
  }

  &_inner {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 10vh 20vh;
  }

  &_kv {
    position: relative;
    display: block;
    width: 90vh;
    margin-top: -20vh;
    margin-left: 20vh;
    z-index: -1;

    img {
      display: block;
      width: 100%;
    }
  }

  &_content {
    margin-top: 20vh;
    margin-left: 15vh;
    display: flex;
    align-items: flex-start;

    p {
      padding-top: $space-3;
      padding-right: $space-3;

      @include mq-up(lg) {
        padding-top: 80px;
      }
    }

    h3 {
      letter-spacing: 0.5em;
    }
  }

  &_illust {
    position: absolute;
    right: 20%;
    bottom: 0;
    width: 30vh;
    overflow: hidden;

    @include mq-up(md) {
      right: 22%;
    }

    @include mq-up(lg) {
      right: 15%;
      bottom: -10vh;
      width: 40vh;
    }

    img {
      width: 100%;
      overflow: hidden;
    }
  }
}

/*--------------------------------------------------
Historyエリア
--------------------------------------------------*/
.lp-history {
  position: relative;
  height: 100%;

  &_inner {
    position: relative;
    display: flex;
    height: 100%;
  }

  &_illust-leaf {
    position: absolute;
    width: 160vh;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: -1;
  }

  &_intro {
    position: relative;
    display: flex;
    align-items: flex-start;
    min-width: 950px;
    width: 130vh;
    padding: 30vh 10vh 10vh 20vh;

    @include mq-up(md) {
      width: 110vh;
    }

    @include mq-up(lg) {
      width: 130vh;
    }

    &_content {
      &_body {
        position: relative;
        display: flex;
        align-items: flex-start;
        margin-top: 10vh;
        margin-left: 10vh;

        p {
          padding-top: $space-xl;
          padding-right: $space-3;

          @include mq-up(lg) {
            padding-top: 120px;
          }
        }

        h3 {
          letter-spacing: 0.5em;
        }
      }
    }
  }

  &_main {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    min-width: 3500px;
    width: 470vh;
    height: 100%;

    @include mq-up(lg) {
      width: 450vh;
    }

    &_inner {
      position: relative;
      width: 100%;
      height: 80%;
      padding: 10vh 20vh;
    }
  }

  &_flow {
    height: 100%;
    display: flex;
    position: relative;
    margin-right: 15vh;

    &_head {
      position: relative;
      height: 100%;
      margin: -77px 5vh 0;

      @include mq-up(md) {
        margin: -86px 5vh 0;
      }

      &::before {
        position: absolute;
        content: '';
        width: calc(130px + 10vh);
        height: 1px;
        top: 77px;
        left: -5vh;
        background-color: $black;

        @include mq-up(md) {
          width: calc(150px + 10vh);
          top: 86px;
        }
      }

      &:first-child {
        &::before {
          position: absolute;
          content: '';
          width: calc(130px + 5vh);
          height: 1px;
          top: 77px;
          left: 0;
          background-color: $black;

          @include mq-up(md) {
            width: calc(150px + 5vh);
            top: 86px;
          }
        }
      }

      &_hexagon {
        width: 130px;
        height: 154px;
        background-color: #48594a;
        clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
        position: relative;
        color: $white;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include mq-up(md) {
          width: 150px;
          height: 172px;
        }

        .title {
          position: relative;
          font-size: 2rem;
          line-height: 3rem;

          &::after {
            content: '';
            position: absolute;
            width: 100px;
            height: 1px;
            bottom: 0;
            left: 15px;
            background: rgba($white, 0.5);

            @include mq-up(md) {
              left: 25px;
            }
          }
        }

        .era {
          font-size: 1.5rem;
          line-height: 3rem;
        }
      }

      &_illust {
        position: absolute;
        top: 25vh;
        left: -3vh;
        width: 20vh;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &_body {
      position: relative;

      &_list {
        display: flex;
        border-top: 1px solid $black;
      }

      &_item {
        position: relative;
        display: flex;
        margin: 0 5vh;
      }

      &_bg {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        margin-top: -30%;
        z-index: -1;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          overflow: hidden;
        }
      }

      &_title {
        position: relative;
        writing-mode: vertical-rl;
        padding-top: 10vh;
        display: flex;
        align-items: center;
        letter-spacing: 0.5em;

        @include mq-up(lg) {
          padding-top: 15vh;
        }

        &::before {
          content: '';
          position: absolute;
          top: -5px;
          left: calc(50% - 4.5px);
          width: 10px;
          height: 10px;
          background-color: $black;
          border-radius: 50%;
          box-sizing: border-box;
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          width: 1px;
          height: 8vh;
          background-color: $black;

          @include mq-up(lg) {
            height: 12vh;
          }
        }

        .generation {
          font-size: 2rem;
        }

        .name {
          font-size: 2.5rem;
          color: #48594a;
          padding-top: 2vh;
        }
      }

      &_content {
        min-width: 370px;
        width: 50vh;
        padding: 10vh 5vh 0;

        @include mq-up(lg) {
          width: 45vh;
          padding: 15vh 5vh 0;
        }
      }

      &_topic {
        padding: 10vh 5vh 0;
        min-width: 370px;
        width: 50vh;
        color: $white;

        @include mq-up(lg) {
          width: 40vh;
          padding: 15vh 5vh 0;
        }

        &_inner {
          height: auto;
          background-color: #a2ada4;
          border-radius: 10px;

          span {
            display: inline-block;
            font-size: 2.3rem;
            padding: 3vh 3vh 2vh;
          }

          p {
            padding: 0 3vh 3vh;
          }
        }
      }

      &_illust {
        position: absolute;
        height: 20vh;
        right: 3vh;
        overflow: hidden;
        margin-top: 5vh;

        @include mq-up(lg) {
          right: 0;
          margin-top: 3vh;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          overflow: hidden;
        }
      }
    }
  }
}

/*--------------------------------------------------
Appreciationエリア
--------------------------------------------------*/
.lp-appreciation {
  position: relative;
  width: 280vh;
  height: 100%;

  &_inner {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    background: linear-gradient(-90deg, #2d6532 0%, transparent 80%);
  }

  &_bg {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
    }

    &_pattern {
      position: absolute;
      top: 0;
      right: 0;
      width: 45%;
      height: 100%;
      overflow: hidden;
      z-index: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
      }
    }
  }

  &_intro {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 15vh;
    padding: 15vh;
    z-index: 1;

    &_title {
      position: absolute;
      bottom: 20vh;
      left: 15vh;

      .c-title-section {
        .ja {
          bottom: 10px;

          @include mq-up(lg) {
            bottom: 30px;
            left: 250px;
          }
        }
      }
    }

    &_kv {
      border-radius: 10px;
      box-shadow: 10px 10px 25px rgba($black, 0.4);
      overflow: hidden;
      width: 100vh;
      height: 70vh;
      margin-left: 40vh;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
      }
    }
  }

  &_main {
    height: 100%;
    padding: 15vh;
    z-index: 1;

    &_inner {
      width: 50vh;
      height: 100%;
      min-width: 270px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        color: $white;
      }
    }

    &_content {
      height: 50%;
      display: flex;
      align-items: flex-start;
      padding-bottom: 5vh;
    }

    &_img {
      height: 100%;
      border-radius: 10px;
      box-shadow: 10px 10px 25px rgba($black, 0.4);
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
      }
    }
  }
}

/*--------------------------------------------------
From now onエリア
--------------------------------------------------*/
.lp-fno {
  position: relative;
  height: 100%;

  &_inner {
    position: relative;
    height: 100%;
    display: flex;
  }

  &_illust-leaf {
    position: absolute;
    width: 160vh;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: -1;
  }

  &_intro {
    position: relative;
    min-width: 800px;
    width: 100vh;
    height: 100%;
    padding: 20vh 10vh 15vh 25vh;

    @include mq-up(md) {
      width: 120vh;
    }

    &_title {
      margin-bottom: 5vh;
    }

    p {
      min-width: 400px;
      width: 60vh;
      padding-left: 5vh;

      @include mq-up(md) {
        width: 50vh;
      }

      @include mq-up(lg) {
        width: 60vh;
      }
    }

    &_bg {
      position: absolute;
      left: 10vh;
      bottom: -4vh;
      width: 60vh;
      z-index: -1;
    }
  }

  &_main {
    position: relative;
    min-width: 1700px;
    width: 200vh;
    height: 100%;
  }

  &_list {
    position: relative;
    height: 100%;
    padding: 20vh 0 15vh;
    display: flex;

    @include mq-up(lg) {
      padding-top: 25vh;
    }
  }

  &_item {
    position: relative;
    min-width: 350px;
    width: 40vh;
    margin: 0 10vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &:nth-of-type(2) {
      margin-top: 5vh;
      margin-bottom: -5vh;
    }

    &:nth-of-type(3) {
      margin-top: 10vh;
      margin-bottom: -10vh;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &_date {
      position: absolute;
      top: 0;
      right: -10vh;
      padding-left: $space-5;
      writing-mode: vertical-rl;

      &::before {
        content: '';
        position: absolute;
        left: -1px;
        top: 0;
        width: 3px;
        height: 70px;
        background: $black;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 1px;
        height: 150px;
        background: $black;
      }
    }

    &_img {
      min-width: 250px;
      min-height: 250px;
      width: 100%;
      height: auto;
      margin: 5vh 0;

      img {
        display: block;
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }

    &_content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      min-width: 250px;
      width: 40vh;

      @include mq-up(md) {
        width: 35vh;
      }

      @include mq-up(lg) {
        width: 30vh;
      }
    }

    &_text {
      margin-bottom: 3vh;
    }
  }
}

/*--------------------------------------------------
Comingエリア
--------------------------------------------------*/
.lp-coming {
  position: relative;
  display: flex;
  height: 100%;
  background-color: #d8d9d2;

  &_inner {
    min-width: 800px;
    width: 100vh;
    height: 100%;
    padding: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq-up(md) {
      width: 130vh;
    }

    @include mq-up(lg) {
      width: 100vw;
    }
  }

  &_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
    }
  }

  &_title {
    position: absolute;
  }
}

/*--------------------------------------------------
フッター
--------------------------------------------------*/
.lp-footer {
  position: relative;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  background-color: rgba(0, 0, 0, 0.8);

  &_inner {
    position: relative;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding: 20vh 5vh 50px 5vh;

    @include mq-up(md) {
      padding-bottom: 5vh;
    }

    @include mq-up(lg) {
      flex-direction: row;
      align-items: center;
      padding: 8vh;
    }
  }

  &-left {
    color: $white;
    width: 100%;

    @include mq-up(lg) {
      width: auto;
    }
  }

  &_list {
    &_item {
      margin-bottom: $space-3;

      @include mq-up(md) {
        margin-bottom: $space-1;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &_title {
      margin-bottom: $space-5;
      font-size: $size-3;
      letter-spacing: 0.05em;
    }
  }

  &_subList {
    &_item {
      position: relative;
      padding: 0 0 $space-5 $space-3;
      letter-spacing: 0.05em;

      @include mq-up(md) {
        padding: 0 0 $space-4 $space-2;
      }

      &:last-child {
        padding-bottom: 0;
      }

      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 1.4vh;
        left: 0;
        width: 22px;
        height: 1px;
        background-color: $white;

        @include mq-up(md) {
          top: 1.2vh;
          width: 30px;
        }
      }

      .title {
        font-size: $size-3;

        @include mq-up(md) {
          font-size: $size-4;
        }
      }

      .link {
        display: inline-block;
        font-size: $size-l;
        color: $white;

        a {
          color: $white;
          text-decoration: none;
        }
      }
    }
  }

  &-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    height: 100%;
    color: $white;
  }

  &_address {
    margin-bottom: $space-4;
    font-size: $size-4;
    letter-spacing: 0.05em;
    line-height: 1.75;
    color: $white;

    @include mq-up(md) {
      margin-bottom: $space-1;
    }

    a {
      color: $white;
      text-decoration: none;
    }
  }

  &_copy {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    @include mq-up(lg) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .copy {
      font-size: $size-5;
    }
  }

  &_sns {
    display: flex;
    align-items: center;
    margin-bottom: $space-4;

    @include mq-up(lg) {
      margin-bottom: 0;
    }

    li {
      margin-right: $space-6;

      a {
        color: $white;
      }
    }

    i {
      font-size: 30px;
    }
  }

  &_backToTop {
    position: absolute;
    display: flex;
    align-items: center;
    left: 0;
    bottom: 10vh;
    color: $white;
    text-decoration: none;
    z-index: 2;
    opacity: 0;

    @include mq-up(lg) {
      opacity: 1;
    }

    &_text {
      display: inline-block;
      margin-left: $space-5;
      font-size: 1.4rem;

      @include mq-up(lg) {
        font-size: 1.6rem;
      }
    }

    &_base {
      content: '';
      display: inline-block;
      width: 80px;
      height: 3px;
      background: $gray;
      opacity: 0.3;

      @include mq-up(md) {
        width: 120px;
      }

      @include mq-up(xl) {
        width: 120px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 80px;
      height: 3px;
      background: $white;
      animation: btl 2s cubic-bezier(1, 0, 0, 1) infinite;

      @include mq-up(md) {
        width: 120px;
      }

      @include mq-up(xl) {
        width: 120px;
      }
    }
  }

  @keyframes btl {
    0% {
      transform: scale(0, 1);
      transform-origin: 100% 0;
    }
    50% {
      transform: scale(1, 1);
      transform-origin: 100% 0;
    }
    50.1% {
      transform: scale(1, 1);
      transform-origin: 0 100%;
    }
    100% {
      transform: scale(0, 1);
      transform-origin: 0 100%;
    }
  }
}
