html {
  height: 100%;
  font-size: 62.5%;
}

body {
  font-family: $family-primary;
  font-size: 1.4rem;
  letter-spacing: 0.05em;
  line-height: 1.75;
  font-weight: 300;
  min-height: 100vh;
  color: $black;
}

main {
  flex: 1;
}

a {
  color: $black;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $secondary;
  }
}

button {
  appearance: none;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  touch-action: manipulation;
}

html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
hr {
  margin: 0;
  padding: 0;
}

ul,
ol,
li {
  list-style: none;
}

img {
  display: block;
  height: auto;
}
