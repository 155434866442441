$sizes: xl, lg, md, sm, xs;
$breakpoint-up: (
  'xs': 'screen and (min-width: 321px)',
  'sm': 'screen and (min-width: 376px)',
  'md': 'screen and (min-width: 744px)',
  'lg': 'screen and (min-width: 1024px)',
  'xl': 'screen and (min-width: 1500px)',
) !default;

//メディアクエリ用mixinを定義（初期値はmd）
@mixin mq-up($breakpoint: md) {
  @media #{map-get($breakpoint-up, $breakpoint)} {
    @content;
  }
}
